import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Card, Accordion } from "react-bootstrap"
import styled from "styled-components"

import Title from "../components/Title"
import { CallToAction, Layout, Seo } from "../components"

export const query = graphql`
  {
    allDatoCmsFqa(sort: { fields: info___title, order: DESC }) {
      edges {
        node {
          id
          info {
            id
            info
            title
          }
          title
        }
      }
    }

    allDatoCmsImage(
      filter: { image: { alt: { eq: "Am House St Alban Vermont" } } }
    ) {
      edges {
        node {
          image {
            alt
            gatsbyImageData
          }
        }
      }
    }

    allDatoCmsPageIntro(filter: { title: { eq: "Faq Page" } }) {
      edges {
        node {
          id
          info
          title
        }
      }
    }
  }
`

const FaqPage = ({ data }) => {
  const {
    allDatoCmsFqa: { edges: fqa },
    allDatoCmsImage: { edges: image },
    allDatoCmsPageIntro: { edges: intro },
  } = data

  return (
    <Layout>
      {image.map(({ node }) => {
        const image = node.image.gatsbyImageData.images.src
        return (
          <Seo
            title="Frequently Asked Questions"
            description="Frequently asked questions about Estate, Automobile Accident Or Premises Lialibility Case, Business, Work Place Injury, and Real Estate"
            image={image}
          />
        )
      })}

      <div className="section page-header">
        <Container>
          <SectionHeading>
            <Row>
              <Col>
                <Title title="frequently asked questions" />
              </Col>
            </Row>
          </SectionHeading>
        </Container>
      </div>

      <Wrapper className="section">
        <Container>
          <Row>
            <Col>
              {intro.map(({ node }) => {
                return <h3 className="page-intro">{node.info}</h3>
              })}
            </Col>
          </Row>

          <Row>
            <Col>
              <article>
                {fqa.map(({ node }) => {
                  return (
                    <>
                      <h3>{node.title}</h3>

                      {node.info.map((item, index) => {
                        return (
                          <>
                            <Accordion key={index}>
                              <Card>
                                <Accordion.Toggle
                                  as={Card.Header}
                                  eventKey={item}
                                >
                                  {item.title}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={item}>
                                  <div
                                    className="card-text-body"
                                    dangerouslySetInnerHTML={{
                                      __html: item.info,
                                    }}
                                  ></div>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </>
                        )
                      })}
                    </>
                  )
                })}
              </article>
            </Col>
          </Row>
        </Container>
      </Wrapper>

      <CallToAction />
    </Layout>
  )
}

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    text-align: center;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background: var(--clr-primary-8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    color: var(--clr-secondary-1);
  }

  .card-text-body {
    padding: 0.75rem 1.25rem;
  }

  h3 {
    padding: 25px 0;
    font-weight: 900;
  }
`

const SectionHeading = styled.section`
  @media screen and (max-width: 480px) {
    padding: 4rem 0 0 0;
  }

  h2.section-heading {
    text-align: center;
    color: var(--clr-primary-6);
    padding-top: 7rem;

    @media screen and (max-width: 480px) {
      padding-top: 2rem;
    }
  }
`

export default FaqPage
